<template>
  <div v-if="posts" class="bg-gray-light p-4">
    <header
      v-if="selected.length > 0"
      class="
        mdc-top-app-bar mdc-top-app-bar--fixed
        bg-secondary
        fixed
        top-0
        left-0
        right-0
      "
    >
      <div class="mdc-top-app-bar__row max-w-screen-xl mx-auto px-4">
        <section
          class="mdc-top-app-bar__section mdc-top-app-bar__section--align-start"
        >
          <span class="flex items-center">
            <i
              class="material-icons cursor-pointer mr-4"
              @click="toggleAll(false)"
              >clear</i
            >
            <span v-text="selected.length + ' selected'" />
          </span>
        </section>
        <section
          class="
            mdc-top-app-bar__section
            mdc-top-app-bar__section--align-end
            mdc-top-app-bar__section--shrink-to-fit
          "
        >
          <mdc-button @click.native="toggleAll(false)"> Cancel </mdc-button>
          <span v-if="$store.state.emailSort.view !== 'trash'">
            <mdc-button
              class="bg-error text-white"
              raised
              @click.native="trashSelected('trash')"
              >Trash</mdc-button
            >
          </span>
          <span v-else>
            <mdc-button primary @click.native="trashSelected('restore')"
              >Restore</mdc-button
            >
            <mdc-button
              raised
              class="ml-2 bg-error text-white"
              @click.native="trashSelected('delete')"
              >Delete Forever</mdc-button
            >
          </span>
        </section>
      </div>
    </header>

    <div class="flex items-center">
      <mdc-button
        :disabled="composeDisabled"
        secondary
        raised
        @click.native="
          $router.push({ name: 'post', params: { hash: 'create' } })
        "
      >
        Compose
      </mdc-button>
      <span
        v-if="composeDisabled"
        class="text-gray-dark flex items-center sm:ml-8"
      >
        <i class="material-icons">arrow_left</i>
        Link your social account to begin!
      </span>
      <a
        class="mdc-button later-icon-facebook ml-4 pl-12"
        href="/social/login/facebook/post"
        raised
        >Refresh Facebook Token</a
      >
    </div>

    <div class="my-8">
      <div
        v-for="post in posts.posts.data"
        :key="post.hash"
        class="
          bg-white
          relative
          my-8
          p-4
          rounded
          shadow
          max-w-screen-md
          min-h-24
        "
      >
        <div
          v-for="social in post.socials"
          :key="social.hash"
          class="flex items-center"
        >
          <img :src="social.avatar" class="rounded-full w-12 h-12" />
          <div class="ml-4">
            <div v-text="social.name" />
            <div
              :title="dateString(post.send_at)"
              class="text-xs text-gray-dark mt-1"
              v-text="post.send_at.isValid ? post.send_at.toLocaleString() : ''"
            />
          </div>
        </div>
        <div class="mt-4 leading-normal" v-html="post.body" />
        <mdc-button
          class="absolute bottom-0 right-0 m-2"
          @click.native="
            $router.push({ name: 'post', params: { hash: post.hash } })
          "
        >
          Edit
        </mdc-button>
        <later-status
          :status="post.deleted_at ? 'trash' : post.status"
          class="absolute top-0 right-0 m-4"
        />
      </div>
    </div>

    <div class="my-8">
      <div v-if="posts.posts.total > 0">
        <strong v-text="posts.posts.from" />-<strong v-text="posts.posts.to" />
        &nbsp; of &nbsp; <strong v-text="posts.posts.total" /> &nbsp;
        <mdc-button
          :disabled="posts.posts.prev_page_url == null"
          compact
          @click.native="nextPage(-1)"
        >
          <i class="material-icons align-middle">keyboard_arrow_left</i>
        </mdc-button>
        <mdc-button
          :disabled="posts.posts.next_page_url == null"
          compact
          @click.native="nextPage(1)"
        >
          <i class="material-icons align-middle">keyboard_arrow_right</i>
        </mdc-button>
      </div>
      <div v-else class="pr-1">No posts yet</div>
      <span v-if="this.$store.state.emailSort.view !== 'all'">
        in <i v-text="this.$store.state.emailSort.view"
      /></span>
    </div>
  </div>
</template>

<script>
import axios from '@/utils/xhr';
import { dateFromServer, dateString } from '@/utils/date';
import MdcButton from '@/components/mdc/button.vue';
import LaterStatus from '@/components/status.vue';

export default {
  components: {
    LaterStatus,
    MdcButton,
  },

  data: () => ({
    posts: null,
  }),

  computed: {
    selected() {
      return this.posts == null
        ? []
        : this.posts.posts.data.filter((email) => email.checked);
    },
    areAllChecked() {
      return (
        this.selected.length > 0 &&
        this.selected.length === this.posts.posts.data.length
      );
    },
  },

  mounted() {
    this.fetch();
  },

  methods: {
    dateString,
    fetch() {
      this.toggleAll(false);
      axios
        .post('/post/results', { ...this.$store.state.emailSort })
        .then((response) => {
          response.data.posts.data.forEach((email, i) => {
            response.data.posts.data[i].checked = false;
            response.data.posts.data[i].updated_at = dateFromServer(
              response.data.posts.data[i].updated_at
            );
            response.data.posts.data[i].send_at = dateFromServer(
              response.data.posts.data[i].send_at
            );
          });
          this.$set(this, 'posts', response.data);
        });
    },
    nextPage(direction) {
      this.$store.commit('nextPage', direction);
      this.fetch();
    },
    setSort(by) {
      this.$store.commit('setSort', by);
      this.fetch();
    },
    toggleAll(check) {
      if (!this.posts) return;
      if (typeof check !== 'boolean') check = !this.areAllChecked;
      this.posts.posts.data.forEach((email, i) => {
        this.posts.posts.data[i].checked = check;
      });
    },
    trashSelected(action) {
      const count = this.selected.length;
      if (count < 1) return;
      axios
        .post('/post/trash', {
          posts: this.selected.map((email) => email.hash),
          action,
        })
        .then(() => {
          let msg = `${count} post${count !== 1 ? 's have' : ' has'} been `;
          if (action === 'trash') msg += 'moved to the trash.';
          if (action === 'restore') msg += 'restored.';
          if (action === 'delete') msg += 'deleted forever.';
          this.$store.commit('snackbarMessage', msg);
          this.fetch();
        });
    },
  },
};
</script>
