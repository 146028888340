<template>
  <span
    :class="{
      'bg-primary-light': status === 'draft',
      'bg-secondary-light': status === 'scheduled',
      'bg-warning': status === 'queued',
      'bg-success': status === 'sent',
      'bg-error text-white': status === 'error' || status === 'trash',
    }"
    class="rounded shadow px-1"
    v-text="status"
  />
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      required: true,
    },
  },
};
</script>
